<script>
import { updateAlertsEmail } from '@/api'

export default {
  metaInfo() {
    return {
      title: 'Verify alerts email - Accounts - Europe PMC',
    }
  },
  props: {
    code: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
      valid: false,
    }
  },
  async created() {
    const { code } = this
    try {
      if (code) {
        const response = await updateAlertsEmail({
          action: 'activateNotificationEmail&activationCode=' + code,
        })
        this.valid = response
      }
    } finally {
      this.loaded = true
    }
  },
}
</script>
<template>
  <div id="verify-alerts-email-page">
    <div class="grid-row">
      <div class="col-16">
        <h1>Europe PMC alerts email</h1>
        <template v-if="loaded">
          <template v-if="valid">
            <p>
              The email address {{ email }} has been confirmed for receiving
              saved alerts.
            </p>
            <p>View your <a href="/accounts/SavedAlerts">Saved alerts</a>.</p>
          </template>
          <p v-else>
            This email verification link is no longer valid. It may have already
            been verified.
          </p>
        </template>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
#verify-alerts-email-page {
  padding-top: $base-unit * 6.5;
  padding-bottom: $base-unit * 13;
}
</style>
